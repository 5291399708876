import React, { useState, useContext, useEffect } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const RequestOTP = () => {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [verificationId, setVerificationId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    let timer;
    if (otpSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    } else if (otpSent && timeLeft === 0) {
      setResendDisabled(false);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpSent, timeLeft]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (!otpSent) {
          handleRequestOtp();
        } else {
          handleVerifyOtp();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpSent, phone, otp]);

  const handleRequestOtp = async () => {
    if (!phone || phone.length < 10) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    setLoading(true);
    setErrorMessage(""); // Clear any existing error
    try {
      const response = await api.post("/api/auth/send-verification-code", {
        phoneNumber: phone,
        countryCode: countryCode.replace("+", ""),
      });
      setOtpSent(true);
      setVerificationId(response.data.verificationId);
      setTimeLeft(30);
      setResendDisabled(true);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error || "Failed to send OTP. Please try again."
      );
      console.error("Error requesting OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || otp.length < 4) {
      setErrorMessage("Please enter a valid OTP.");
      return;
    }

    setLoading(true);
    setErrorMessage(""); // Clear any existing error
    try {
      const fullPhoneNumber = `${countryCode}${phone}`;
      const response = await api.post("/api/auth/verify-otp", {
        phoneNumber: fullPhoneNumber,
        verificationId: verificationId,
        code: otp,
      });

      const { accessToken } = response.data;

      localStorage.setItem("accessToken", accessToken);
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      setIsAuthenticated(true);
      navigate("/");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error || "Failed to verify OTP. Please try again."
      );
      console.error("Error verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  if (isAuthenticated === null || isAuthenticated) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="w-full max-w-md px-4">
        <div className="flex justify-start mb-6">
          <img
            src="images/logo/logo.png"
            className="w-44"
            alt="Retailwise Logo"
          />
        </div>

        <div className="border-t border-[#B4B4B4] pt-6">
          <h1 className="text-3xl font-bold text-left mb-2 font-sans font-[700]">
            Login to Operations Portal
          </h1>
          <p className="text-gray-500 text-left mb-6 font-sans font-[400]">
            Enter your phone number to receive an OTP
          </p>

          {errorMessage && (
            <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              {errorMessage}
            </div>
          )}

          <div className="mb-4 flex items-center border border-gray-300 rounded overflow-hidden">
            <select
              className="bg-gray-100 p-2 border-r border-gray-300 focus:outline-none"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              disabled={otpSent}
            >
              <option value="+91">+91 (India)</option>
              <option value="+1">+1 (USA)</option>
              <option value="+44">+44 (UK)</option>
            </select>
            <input
              type="text"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setErrorMessage("");
              }}
              className="flex-1 p-2 focus:outline-none bg-white"
              disabled={otpSent}
            />
          </div>

          {otpSent && (
            <>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setErrorMessage("");
                  }}
                  className="p-2 border border-gray-300 rounded w-full focus:outline-none"
                />
              </div>
              <div className="mb-4 text-right">
                {resendDisabled ? (
                  <p className="text-sm text-gray-500">
                    You can resend OTP in {timeLeft} seconds
                  </p>
                ) : (
                  <button
                    onClick={handleRequestOtp}
                    className="text-sm text-blue-500 hover:text-blue-700"
                    disabled={loading}
                  >
                    Resend OTP
                  </button>
                )}
              </div>
            </>
          )}

          {!otpSent ? (
            <button
              onClick={handleRequestOtp}
              className="w-full bg-teal-500 text-white py-2 rounded hover:bg-teal-600 transition"
              disabled={loading}
            >
              {loading ? (
                <span className="flex justify-center items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8z"
                    ></path>
                  </svg>
                  Sending...
                </span>
              ) : (
                "Request OTP"
              )}
            </button>
          ) : (
            <button
              onClick={handleVerifyOtp}
              className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition"
              disabled={loading}
            >
              {loading ? (
                <span className="flex justify-center items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8z"
                    ></path>
                  </svg>
                  Verifying...
                </span>
              ) : (
                "Verify OTP"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestOTP;
