import React, { createContext, useState } from "react";
import api from "../api";
import { toast } from "react-toastify";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState({
    data: [],
    loading: false,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });
  const [error, setError] = useState(null);
  const limit = 10; // Items per page

  // Helper function to update the orders state
  const setState = (updatedState) => {
    setOrders((prevState) => ({ ...prevState, ...updatedState }));
  };

  /**
   * Fetch orders with pagination and search
   * @param {number} page - Current page number
   * @param {string} searchTerm - Search term for filtering orders
   */
  const fetchOrders = async (page = 1, searchTerm = "") => {
    setError(null);
    setState({ loading: true });

    try {
      const response = await api.get(`/api/orders`, {
        params: { page, limit, search: searchTerm },
      });

      const pagination = response.data.pagination || {};
      // console.log("orders", response.data)
      setState({
        data: response.data.data,
        loading: false,
        pagination: {
          totalItems: pagination.totalItems || 0,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.currentPage || 1,
          itemsPerPage: pagination.itemsPerPage || limit,
        },
      });
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        "Failed to fetch orders. Please try again later.";
      console.error("Fetch Orders Error:", errorMessage);
      setError(errorMessage);
      setState({ loading: false });
    }
  };

  /**
   * Add a new order
   * @param {object} orderData - Data for the new order
   */
  const addOrder = async (orderData) => {
    if (!orderData) {
      throw new Error("Order data is required");
    }

    try {
      const response = await api.post("/api/orders", orderData);
      toast.success("Order added successfully!");
      // Optionally, you can refetch orders or update state here
      await fetchOrders(orders.pagination.currentPage, "");
      return response.data;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to add order.";
      console.error("Add Order Error:", errorMessage);
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  /**
   * Update the state of an order
   * @param {string|number} order_id - ID of the order to update
   * @param {string} newState - New state to set
   */
  const updateOrderState = async (order_id, newState) => {
    if (!order_id || !newState) {
      throw new Error("Order ID and new state are required");
    }

    try {
      await api.put(
        `/api/order-state-transitions/orders/${order_id}/state`,
        { new_state: newState },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success("Order state updated successfully!");
      // Optionally, you can refetch orders or update state here
      await fetchOrders(orders.pagination.currentPage, "");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update order state.";
      console.error("Update Order State Error:", errorMessage);
      setError(errorMessage);
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  /**
   * Update the fulfillment type of an order
   * @param {string|number} order_id - ID of the order to update
   * @param {string} newFulfillmentType - New fulfillment type to set
   */
  const updateOrderFulfillment = async (order_id, newFulfillmentType) => {
    if (!order_id || !newFulfillmentType) {
      throw new Error("Order ID and new fulfillment type are required");
    }

    try {
      await api.put(
        `/api/orders/${order_id}/fulfillment`,
        { fulfillment_type: newFulfillmentType },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success("Order fulfillment type updated successfully!");
      // Optionally, you can refetch orders or update state here
      await fetchOrders(orders.pagination.currentPage, "");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update fulfillment type.";
      console.error("Update Order Fulfillment Error:", errorMessage);
      setError(errorMessage);
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  /**
   * Create an invoice for an order
   * @param {object} payload - Contains order_id, invoiceId, and invoiceFile
   */
  const createInvoice = async ({ order_id, invoiceId, invoiceFile }) => {
    if (!order_id) {
      throw new Error("Order ID is required");
    }

    try {
      const formData = new FormData();
      formData.append("order_id", order_id);
      if (invoiceId) {
        formData.append("invoice_ref", invoiceId);
      }
      if (invoiceFile) {
        formData.append("invoice", invoiceFile);
      }

      const response = await api.post("/api/order-invoice", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success("Invoice created successfully!");
      // Optionally, you can refetch orders or update state here
      await fetchOrders(orders.pagination.currentPage, "");
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to create invoice.";
      console.error("Create Invoice Error:", errorMessage);
      setError(errorMessage);
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  /**
   * Soft delete (deactivate) an order
   * @param {string|number} order_id - ID of the order to delete
   */
  const deleteOrder = async (order_id) => {
    if (!order_id) {
      console.error("Order ID is required");
      throw new Error("Order ID is required");
    }

    try {
      // console.log(`Deleting (deactivating) order with ID: ${order_id}`);
      await api.patch(`/api/orders/deactivate/${order_id}`);
      // Refresh orders after deletion
      await fetchOrders(orders.pagination.currentPage, "");
      toast.success("Order deleted successfully!");
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to delete order.";
      console.error("Delete Order Error:", errorMessage);
      setError(errorMessage);
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  /**
   * Get a specific order by ID
   * @param {string|number} order_id - ID of the order to retrieve
   */
  const getOrderById = async (order_id) => {
    if (!order_id) {
      throw new Error("Order ID is required");
    }

    try {
      // console.log(`Fetching order with ID: ${order_id}`);
      const response = await api.get(`/api/orders/${order_id}`);
      return response.data;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to fetch order details.";
      console.error("Get Order By ID Error:", errorMessage);
      throw new Error(errorMessage);
    }
  };

  return (
    <OrderContext.Provider
      value={{
        orders,
        fetchOrders,
        addOrder,
        updateOrderState,
        updateOrderFulfillment,
        createInvoice,
        deleteOrder,
        getOrderById,
        loading: orders.loading,
        error,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
