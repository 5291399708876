import React, { createContext, useState } from "react";
import api from "../api";

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [events, setEvents] = useState({
    data: [],
    loading: false,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });
  const [error, setError] = useState(null);
  const limit = 10; // Items per page

  // Helper function to update the events state
  const setState = (updatedState) => {
    setEvents((prevState) => ({ ...prevState, ...updatedState }));
  };

  // Fetch events with pagination and search
  const fetchEvents = async (page = 1, searchTerm = "") => {
    setError(null);
    setState({ loading: true });

    try {
      const response = await api.get(`/api/events`, {
        params: { page, limit, search: searchTerm },
      });

      // console.log("FetchEvents Response:", response.data); // Debugging

      const pagination = response.data.pagination || {};
      setState({
        data: response.data.data,
        loading: false,
        pagination: {
          totalItems: pagination.totalItems || 0,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.currentPage || 1,
          itemsPerPage: pagination.itemsPerPage || limit,
        },
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch events. Please try again later.";
      console.error("Fetch Events Error: ", errorMessage);
      setError(errorMessage);
      setState({ loading: false });
    }
  };

  // Add a new event
  const addEvent = async (eventData) => {
    // Validate required fields
    if (
      !eventData.eventName ||
      !eventData.hotspotId ||
      !eventData.date ||
      !eventData.startTime ||
      !eventData.endTime
    ) {
      throw new Error("All required fields must be filled.");
    }

    try {
      const response = await api.post("/api/events", eventData);
      // After adding, refetch the first page to include the new event
      await fetchEvents(1);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to add event.";
      throw new Error(errorMessage);
    }
  };

  // Update an existing event
  const updateEvent = async (id, updatedData) => {
    if (!id || !updatedData) {
      throw new Error("Event ID and data are required.");
    }

    try {
      const response = await api.put(`/api/events/${id}`, updatedData);
      setEvents((prevEvents) => ({
        ...prevEvents,
        data: prevEvents.data.map((event) =>
          event.id === id ? response.data.data : event
        ),
      }));
      // Refetch events to ensure pagination and data consistency
      await fetchEvents(events.pagination.currentPage, "");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update event.";
      throw new Error(errorMessage);
    }
  };

  // Delete (Deactivate) an event
  const deleteEvent = async (id) => {
    if (!id) {
      console.error("Event ID is required");
      throw new Error("Event ID is required");
    }

    try {
      await api.patch(`/api/events/${id}/deactivate`);
      // After deleting, refetch the current page
      await fetchEvents(events.pagination.currentPage, "");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to delete event.";
      console.error("Error in deleteEvent:", errorMessage);
      setError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  return (
    <EventContext.Provider
      value={{
        events,
        fetchEvents,
        addEvent,
        updateEvent,
        deleteEvent,
        loading: events.loading,
        error,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
