import React from "react";
import { ClipLoader } from "react-spinners";

const Loader = ({ size = 35, color = "#4A90E2" }) => {
  return (
    <div
      className="flex items-center justify-center space-x-3"
      role="status"
      aria-live="polite"
    >
      <ClipLoader size={size} color={color} />
    </div>
  );
};

export default Loader;
