import React, { useState, useEffect } from "react";
import api from "../api";
import { format } from "date-fns";
import Loader from "../components/Loader";

const AddEditInventoryModal = ({
  showModal,
  setShowModal,
  currentInventory,
  fetchInventories,
}) => {
  const [inventory, setInventory] = useState({
    brand_id: "",
    catalog_id: "",
    date_of_movement: "", // Will be set to today's date
    qty: "",
    ref_id: "",
  });

  const [brands, setBrands] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const initializeForm = async () => {
      setModalLoading(true);
      const today = format(new Date(), "yyyy-MM-dd");

      try {
        const [brandsResponse, catalogsResponse] = await Promise.all([
          api.get("/api/brands"),
          api.get("/api/catalogs"),
        ]);

        setBrands(brandsResponse.data.data || []);
        setCatalogs(catalogsResponse.data.data || []);

        if (currentInventory) {
          setInventory({
            brand_id: currentInventory.brand_id || "",
            catalog_id: currentInventory.catalog_id || "",
            date_of_movement: today,
            qty: currentInventory.qty || "",
            ref_id: currentInventory.ref_id || "",
          });
        } else {
          setInventory({
            brand_id: "",
            catalog_id: "",
            date_of_movement: today,
            qty: "",
            ref_id: "",
          });
        }

        setError("");
        setSuccessMessage("");
        setModalLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again.");
        setModalLoading(false);
      }
    };

    if (showModal) {
      initializeForm();
    }
  }, [currentInventory, showModal]);

  useEffect(() => {
    if (catalogs.length > 0 && inventory.brand_id) {
      const filtered = catalogs.filter(
        (catalog) => catalog.brand_id === inventory.brand_id
      );
      setFilteredCatalogs(filtered);
    } else {
      setFilteredCatalogs([]);
    }
  }, [catalogs, inventory.brand_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInventory({ ...inventory, [name]: value });
  };

  const resetForm = () => {
    const today = format(new Date(), "yyyy-MM-dd");
    setInventory({
      brand_id: "",
      catalog_id: "",
      date_of_movement: today,
      qty: "",
      ref_id: "",
    });
    setError("");
    setSuccessMessage("");
    setFilteredCatalogs([]);
  };

  const handleSubmit = async () => {
    if (
      !inventory.brand_id ||
      !inventory.catalog_id ||
      !inventory.date_of_movement ||
      !inventory.qty
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      await api.post("/api/inventory", inventory);
      setSuccessMessage("Inventory updated successfully!");
      setShowModal(false);
      fetchInventories();
    } catch (error) {
      console.error("Error saving inventory:", error);
      setError("An error occurred while updating the inventory.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 ${
        showModal ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg p-6 w-96">
        {modalLoading ? (
          <div className="flex justify-center items-center h-48">
            <Loader size={40} color={"#4A90E2"} message="Loading form..." />
          </div>
        ) : (
          <>
            <h2 className="text-xl font-bold mb-4">
              {currentInventory ? "Update Inventory" : "Add Inventory"}
            </h2>

            {error && <p className="text-red-500 mb-4">{error}</p>}
            {successMessage && (
              <p className="text-green-500 mb-4">{successMessage}</p>
            )}

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Brand <span className="text-red-500">*</span>
              </label>
              <select
                name="brand_id"
                value={inventory.brand_id}
                onChange={handleInputChange}
                className="px-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Brand</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.brand_id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Catalog <span className="text-red-500">*</span>
              </label>
              <select
                name="catalog_id"
                value={inventory.catalog_id}
                onChange={handleInputChange}
                className="px-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Catalog</option>
                {filteredCatalogs.map((catalog) => (
                  <option key={catalog.id} value={catalog.catalog_id}>
                    {catalog.product_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Date of Movement <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                name="date_of_movement"
                value={inventory.date_of_movement}
                onChange={handleInputChange}
                className="px-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Quantity <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="qty"
                value={inventory.qty}
                onChange={handleInputChange}
                className="px-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter quantity"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Reference ID
              </label>
              <input
                type="text"
                name="ref_id"
                value={inventory.ref_id}
                onChange={handleInputChange}
                className="px-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter reference ID (optional)"
              />
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  resetForm();
                  setShowModal(false);
                }}
                className="mr-2 bg-gray-500 text-white py-1 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className={`bg-green-500 text-white py-1 px-4 rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Saving..." : currentInventory ? "Update" : "Add"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddEditInventoryModal;
