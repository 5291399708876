import React, { createContext, useState } from "react";
import api from "../api";

const HotspotContext = createContext();

export const HotspotProvider = ({ children }) => {
  const [hotspots, setHotspots] = useState({
    data: [],
    loading: false,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });
  const [error, setError] = useState(null);
  const limit = 10; // Set limit per page

  // Helper function to update the hotspots state
  const setState = (updatedState) => {
    setHotspots((prevState) => ({ ...prevState, ...updatedState }));
  };

  // Fetch hotspots with pagination and search
  const fetchHotspots = async (page = 1, searchTerm = "") => {
    setError(null);
    setState({ loading: true });

    try {
      const response = await api.get(`/api/hotspots`, {
        params: { page, limit, search: searchTerm },
      });

      const pagination = response.data.pagination || {};
      setState({
        data: response.data.data,
        loading: false,
        pagination: {
          totalItems: pagination.totalItems || 0,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.currentPage || 1,
          itemsPerPage: pagination.itemsPerPage || limit,
        },
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch hotspots. Please try again later.";
      console.error("Fetch Hotspots Error: ", errorMessage);
      setError(errorMessage);
      setState({ loading: false });
    }
  };

  // Add a new hotspot using URL
  const addHotspot = async (url, currentPage, searchTerm) => {
    if (!url) {
      throw new Error("Hotspot URL is required");
    }

    try {
      const response = await api.post("/api/hotspots/v2/add-from-url", { url });
      await fetchHotspots(currentPage, searchTerm);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle 409 Conflict (Hotspot already exists)
        const existingHotspotId = error.response.data.data.hotspot_id;
        return {
          message: "Hotspot already exists.",
          hotspotExists: true,
          existingHotspotId,
        };
      }

      const errorMessage =
        error.response?.data?.message || "Failed to add hotspot.";
      throw new Error(errorMessage);
    }
  };

  // Update an existing hotspot
  const updateHotspot = async (id, updatedData, currentPage, searchTerm) => {
    if (!id || !updatedData) {
      throw new Error("Hotspot ID and data are required.");
    }

    try {
      await api.put(`/api/hotspots/${id}`, updatedData);
      // After updating, refetch the current page with existing search term
      await fetchHotspots(currentPage, searchTerm);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update hotspot.";
      throw new Error(errorMessage);
    }
  };

  // Delete (Deactivate) a hotspot
  const deleteHotspot = async (id, currentPage, searchTerm) => {
    if (!id) {
      console.error("Hotspot ID is required");
      throw new Error("Hotspot ID is required");
    }

    try {
      await api.patch(`/api/hotspots/deactivate/${id}`);
      // After deleting, refetch the current page with existing search term
      await fetchHotspots(currentPage, searchTerm);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to delete hotspot.";
      console.error("Error in deleteHotspot:", errorMessage);
      setError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  // Search hotspots
  const searchHotspots = async (query) => {
    if (!query || query.trim() === "") {
      return [];
    }
    try {
      const response = await api.get(`/api/hotspots/search`, {
        params: { q: query },
      });
      // console.log(response.data)
      return response.data.data;
    } catch (error) {
      // console.error("Search Hotspots Error:", error);
      setError(
        error.response?.data?.message ||
          "Failed to search hotspots. Please try again."
      );
      return [];
    }
  };

  // Fetch a hotspot by ID
  const fetchHotspotById = async (id) => {
    try {
      const response = await api.get(`/api/hotspots/${id}`);
      return response.data.data; // Adjust based on your response structure
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        `Failed to fetch hotspot with ID ${id}.`;
      console.error("Fetch Hotspot By ID Error: ", errorMessage);
      setError(errorMessage);
      return null;
    }
  };

  return (
    <HotspotContext.Provider
      value={{
        hotspots,
        fetchHotspots,
        addHotspot,
        updateHotspot,
        deleteHotspot,
        searchHotspots,
        fetchHotspotById,
        loading: hotspots.loading,
        error,
      }}
    >
      {children}
    </HotspotContext.Provider>
  );
};

export default HotspotContext;
