import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import api from "../api";

const Navbar = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For toggling the hamburger menu

  const handleLogout = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      await api.post("/api/auth/logout");
      setIsAuthenticated(false);
      localStorage.removeItem("accessToken");
      navigate("/login");
    } catch (error) {
      setErrorMessage("Failed to log out. Please try again.");
      console.error("Error during logout:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleHome = () => {
    navigate("/");
  };

  // Toggle hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white border-b p-4 md:px-8 flex justify-between items-center relative">
      {/* Logo */}
      <div className="flex items-center">
        <img
          src="https://res.cloudinary.com/delwjbj2a/image/upload/v1732644005/Group_467_s9bz4r.png"
          alt="Logo"
          className="h-10 w-auto mr-2 cursor-pointer"
          onClick={handleHome}
        />

      </div>

      {/* Hamburger Icon for mobile */}
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>
      </div>

      {/* Menu Items for desktop */}
      <div className="hidden md:flex items-center space-x-6">
        <button
          onClick={handleHome}
          className="text-gray-600 hover:text-gray-800 py-1 px-3"
          disabled={loading}
        >
          Home
        </button>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-600 transition duration-200"
          disabled={loading}
        >
          {loading ? "Logging out..." : "Log Out"}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-16 left-0 w-full bg-white shadow-lg md:hidden z-20">
          <div className="flex flex-col space-y-4 p-4">
            <button
              onClick={handleHome}
              className="text-gray-600 hover:text-gray-800 py-2 px-4 text-center border-black border rounded"
              disabled={loading}
            >
            &larr; Home
            </button>
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
              disabled={loading}
            >
              {loading ? "Logging out..." : "Log Out"}
            </button>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="text-red-600 text-sm mt-2 md:absolute md:right-4">
          {errorMessage}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
